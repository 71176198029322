import { TableCell } from "./components/TableCell"
import { EditCell } from "./components/EditCell"
import { qsiprovidercodes } from "./qsiprovider";
import { specialtycodes } from "./specialtycodes";
import { vaccines } from "./vaccines";
import { formselects } from "./FormSelects";
//import { columns_cis } from "./components/formColumns/columns-cis"; 
//or put it in dynamo
//console.log(formselects['ppc']['cptii'])
export const columns_cis = [
    {    
        accessorKey: "memberid",
        header: "Member ID",
        cell: TableCell,
        meta: {
            type: "number",
            required: true,
            pattern: '/[^0-9]/g',
            validate: (value) => {
              return value.length == 8; //date <= today;
            },
            validationMessage: 'Member ID must be 9 digits',
        }
    },
    {
        accessorKey:"servicedate", 
        header: "Service Date",
        cell: TableCell,
        meta: {
          type: "date",
          required: true,
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();

            var fiveyearsback = new Date(); 
            fiveyearsback.setHours(0,0,0,0);
            fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);
            /*
            console.log(value);
            console.log("DATE:", mydate.toUTCString());
            console.log("FIVE:", fiveyearsback);
            console.log("TODAY:", today);
            console.log( mydate >= fiveyearsback)
            */
            return (mydate <= today && mydate >= fiveyearsback);
          },
          validationMessage: 'Date cannot be in the future or older than 5 years',
        }
    },
    {
        accessorKey: "fname",
        header: "First Name",
        enableColumnFilter: true,
        filterFn: "includesString",
        cell: TableCell,
        meta: {
          type: "text",
          required: true,
        }
    },
    {
        accessorKey: "lname", 
        header: "Last Name",
        cell: TableCell,
        meta: {
          type: "text",
          required: true,
        }
      },  
      {
        accessorKey:"dob", 
        header: "Date Of Birth",
        cell: TableCell,
        meta: {
          type: "date",
          required: true,
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();
            var wayback = new Date('1900-01-01 00:00:00'); 

            return (mydate <= today && mydate >= wayback);
          },
          validationMessage: 'Date cannot be in the future or before 1900',
        }
      },
      {
        accessorKey:"vaccine", 
        header: "Vaccine",
        cell: TableCell,
        meta: {
          type: "select",
          alsoupdate: "cvx",
          options: vaccines,
          required: true,
        }
      },
      {
        accessorKey:"cvx", 
        header: "CVX",
        cell: TableCell,
        meta: {
          type: "text",
          readOnly: true,
          required: false,
        }
      },
      {
        accessorKey:"specialtycode", 
        header: "Specialty Code",
        cell: TableCell,
        meta: {
          type: "select",
          options: specialtycodes,
          required: true,
        }
      },
      {
        accessorKey:"qsiprovider", 
        header: "QSI Provider",
        cell: TableCell,
        meta: {
          type: "select",
          options: qsiprovidercodes,
          required: true,
        }
      },  
      {
        id: "edit",
        db_table: "cis",
        cell: EditCell,
        size: 10
      }
    ]


export const columns_cbp = [
    {
      accessorKey: "memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
    accessorKey: "servicedate", 
      header: "Service Date",
      cell: TableCell,
      meta: {
        type: "date",
        required: true,
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();

          var fiveyearsback = new Date(); 
          fiveyearsback.setHours(0,0,0,0);
          fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);
          /*
          console.log(value);
          console.log("DATE:", mydate.toUTCString());
          console.log("FIVE:", fiveyearsback);
          console.log("TODAY:", today);
          console.log( mydate >= fiveyearsback)
          */
          return (mydate <= today && mydate >= fiveyearsback);
        },
        validationMessage: 'Date cannot be in the future or older than 5 years',
      }
    },
    {
    accessorKey: "fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text"
      }
    },
    {
    accessorKey: "lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text"
      }
    },
    {
    accessorKey: "icd10code", 
      header: "ICD10",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['cbp']['icd10'],
        required: true,
      }
    },
    {
    accessorKey: "cptcode", 
      header: "CPT",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['cbp']['cpt'],
        required: true,
      }
    },
    {
    accessorKey: "cptiicodesystolic", 
      header: "CPTII Systolic",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['cbp']['cptiisystolic'],
        required: true,
      }
    },
    {
      accessorKey: "systolicreading", 
        header: "Systolic Reading",
        cell: TableCell,
        meta: {
          type: "decimal",
          maxValue: 999.99,
          required: true,
        }
    },    
    {
    accessorKey: "cptiicodediastolic", 
      header: "CPTII Diastolic",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['cbp']['cptiidiastolic'],
        required: true,
      }
    },
    {
      accessorKey: "diastolicreading", 
        header: "Diastolic Reading",
        cell: TableCell,
        meta: {
          type: "decimal",
          maxValue: 999.99,
          required: true,
        }
    },
    {  
    accessorKey: "specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
    accessorKey: "qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    }, 
    {
      id: "edit",
      db_table: "cbp",
      cell: EditCell
    }
  ]

  export const columns_ppc = [
    {
    accessorKey: "memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
    accessorKey: "servicedate", 
      header: "Service Date",
      cell: TableCell,
      meta: {
        type: "date",
        required: true,
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();

          var fiveyearsback = new Date(); 
          fiveyearsback.setHours(0,0,0,0);
          fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

          return (mydate <= today && mydate >= fiveyearsback);
        },
        validationMessage: 'Date cannot be in the future or older than 5 years',
      }
    },  
    {
    accessorKey: "fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
      accessorKey: "lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
      accessorKey: "icd10code", 
      header: "ICD10",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['ppc']['icd10'],
        required: true,
      }
    },
    {
      accessorKey: "trimester", 
      header: "Trimester",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },    
    {
      accessorKey: "cptcode", 
      header: "CPT",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['ppc']['cpt'],
        required: true,
      }
    },
    {
      accessorKey: "cptiicode", 
      header: "CPTII Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['ppc']['cptii'],
        required: true,
      }
    }, 
    {
      accessorKey: "specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
      accessorKey: "qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "ppc",
      size: 10,
      cell: EditCell
    }
  ]

  export const columns_gsd = [
    {
    accessorKey:"memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
      accessorKey:"servicedate", 
        header: "Service Date",
        cell: TableCell,
        meta: {
          type: "date",
          required: true,
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();

            var fiveyearsback = new Date(); 
            fiveyearsback.setHours(0,0,0,0);
            fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

            return (mydate <= today && mydate >= fiveyearsback);
          },
          validationMessage: 'Date cannot be in the future or older than 5 years',
        }
    },
    {
    accessorKey:"fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
    accessorKey:"icd10code", 
      header: "ICD10",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['gsd']['icd10'],
        required: true,
      }
    },
    {
    accessorKey:"cptcode", 
      header: "CPT",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['gsd']['cpt'],
        required: true,
      }
    },
    {
      accessorKey:"loinccode", 
        header: "LOINC",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['gsd']['loinc'],
          required: false,
        }
    },
    {
    accessorKey:"a1c", 
      header: "A1C",
      cell: TableCell,
      meta: {
        type: "text",
        required: false,
      }
    },
    {
    accessorKey:"cptii", 
      header: "CPTII",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['gsd']['cptii'],
        required: true,
      }
    },
    {
      accessorKey:"specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
      accessorKey:"qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "gsd",
      cell: EditCell
    }
  ]

  export const columns_eed = [
    {
    accessorKey:"memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
      accessorKey:"servicedate", 
        header: "Service Date",
        cell: TableCell,
        meta: {
          type: "date",
          required: true,
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();

            var fiveyearsback = new Date(); 
            fiveyearsback.setHours(0,0,0,0);
            fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

            return (mydate <= today && mydate >= fiveyearsback);
          },
          validationMessage: 'Date cannot be in the future or older than 5 years',
        }
    },
    {
    accessorKey:"fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
    accessorKey:"icd10code", 
      header: "ICD10",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['gsd']['icd10'],
        required: true,
      }
    },
    {
    accessorKey:"cptii", 
      header: "CPTII",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['eed']['cptii'],
        required: true,
      }
    },
    {
      accessorKey:"specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
      accessorKey:"qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "eed",
      cell: EditCell
    }
  ]

  export const columns_bpd = [
    {
    accessorKey:"memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
      accessorKey:"servicedate", 
        header: "Service Date",
        cell: TableCell,
        meta: {
          type: "date",
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();

            var fiveyearsback = new Date(); 
            fiveyearsback.setHours(0,0,0,0);
            fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

            return (mydate <= today && mydate >= fiveyearsback);
          },
          validationMessage: 'Date cannot be in the future or older than 5 years',
        }
    },
    {
    accessorKey:"fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
    accessorKey:"icd10code", 
      header: "ICD10",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['bpd']['icd10'],
        required: true,
      }
    },
    {
    accessorKey:"cptii_systolic", 
      header: "CPTII Systolic",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['bpd']['cptiisystolic'],
        required: true,
      }
    },
    {
      accessorKey:"systolic_reading", 
        header: "Systolic Reading",
        cell: TableCell,
        meta: {
          type: "decimal",
          maxValue: 999.99,
          required: true,
        }
      },
    {
      accessorKey:"cptii_diastolic", 
      header: "CPTII Diastolic",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['bpd']['cptiidiastolic'],
        required: true,
      }
    },      
    {
      accessorKey:"diastolic_reading", 
      header: "Diastolic Reading",
      cell: TableCell,
      meta: {
        type: "decimal",
        maxValue: 999.99,
        required: true,
      }
    }, 
    {
      accessorKey:"specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
      accessorKey:"qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "bpd",
      cell: EditCell
    }
  ]

  export const columns_ccs = [
    {
    accessorKey:"memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
    accessorKey:"servicedate", 
      header: "Service Date",
      cell: TableCell,
      meta: {
        type: "date",
        required: true,
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();

          var fiveyearsback = new Date(); 
          fiveyearsback.setHours(0,0,0,0);
          fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

          return (mydate <= today && mydate >= fiveyearsback);
        },
        validationMessage: 'Date cannot be in the future or older than 5 years',
      }
    },  
    {
    accessorKey:"fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
    accessorKey:"icd10code", 
      header: "ICD10",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['ccs']['icd10'],
        required: true,
      }
    },
    {
    accessorKey:"cptcode", 
      header: "CPT",
      cell: TableCell,
      size: 10,
      meta: {
        type: "select",
        options: formselects['ccs']['cpt'],
        required: true,
      }
    },
    {
    accessorKey:"cltv", 
      header: "CLTV",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['ccs']['cltv'],
        required: true,
      }
    }, 
    {
    accessorKey:"loinccode", 
      header: "LOINC",
      cell: TableCell,
      meta: {
        type: "select",
        options: formselects['ccs']['loinc'],
        required: true,
      }
    }, 
    {
    accessorKey:"specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
    accessorKey:"qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "ccs",
      size: 10,
      cell: EditCell
    }
  ]

  export const columns_lsc = [
    {
    accessorKey:"memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
    accessorKey:"servicedate", 
      header: "Service Date",
      cell: TableCell,
      meta: {
        type: "date",
        required: true,
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();

          var fiveyearsback = new Date(); 
          fiveyearsback.setHours(0,0,0,0);
          fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

          return (mydate <= today && mydate >= fiveyearsback);
        },
        validationMessage: 'Date cannot be in the future or older than 5 years',
      }
    },
    {  
    accessorKey:"fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"cptcode", 
      header: "CPT",
      cell: TableCell,
      meta: {
        type: "select",
        options: [ 
          { value: '', label: "Please Select ..."},
          { label: '83655' }                        
        ],
        required: true,
      }
    },
    { 
    accessorKey:"specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
    accessorKey:"qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "lsc",
      cell: EditCell
    }
  ]


  export const columns_apm = [
    {
    accessorKey:"memberid", 
      header: "Member ID",
      cell: TableCell,
      meta: {
        type: "number",
        required: true,
        pattern: '/[^0-9]/g',
        validate: (value) => {
          return value.length == 8; //date <= today;
        },
        validationMessage: 'Member ID must be 9 digits',
      }
    },
    {
    accessorKey:"fname", 
      header: "First Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"lname", 
      header: "Last Name",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
    accessorKey:"glucosecollectiondate", 
      header: "Glucose Collection Date",
      cell: TableCell,
      meta: {
        type: "date",
        required: true,
      }
    },
    {
    accessorKey:"cptcode", 
      header: "CPT",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    },
    {
    accessorKey:"ldlcollectiondate", 
      header: "LDL Collection Date",
      cell: TableCell,
      meta: {
        type: "date",
        required: true,
      }
    }, 
    {
    accessorKey:"cptiicode", 
      header: "CPTII",
      cell: TableCell,
      meta: {
        type: "text",
        required: true,
      }
    }, 
    {
    accessorKey:"specialtycode", 
      header: "Specialty Code",
      cell: TableCell,
      meta: {
        type: "select",
        options: specialtycodes,
        required: true,
      }
    },
    {
    accessorKey:"qsiprovider", 
      header: "QSI Provider",
      cell: TableCell,
      meta: {
        type: "select",
        options: qsiprovidercodes,
        required: true,
      }
    },  
    {
      id: "edit",
      db_table: "apm",
      cell: EditCell
    }
  ]


  export const columns_wcv = [
      {
        accessorKey:"memberid", 
        header: "Member ID",
        cell: TableCell,
        meta: {
          type: "number",
          required: true,
          pattern: '/[^0-9]/g',
          validate: (value) => {
            return value.length == 8; //date <= today;
          },
          validationMessage: 'Member ID must be 9 digits',
        }
      },
      {
        accessorKey:"servicedate", 
        header: "Service Date",
        cell: TableCell,
        meta: {
          type: "date",
          required: true,
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();

            var fiveyearsback = new Date(); 
            fiveyearsback.setHours(0,0,0,0);
            fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

            return (mydate <= today && mydate >= fiveyearsback);
          },
          validationMessage: 'Date cannot be in the future or older than 5 years',
        }
      },  
      {
        accessorKey:"fname", 
        header: "First Name",
        cell: TableCell,
        meta: {
          type: "text",
          required: true,
        }
      },
      {
        accessorKey:"lname", 
        header: "Last Name",
        cell: TableCell,
        meta: {
          type: "text",
          required: true,
        }
      }, 
      {
        accessorKey:"icd10code", 
        header: "ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['icd10'],
          required: true,
        }
      },
      {
        accessorKey:"cptcode", 
        header: "CPT",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['cpt'],
          required: true,
        }
      },
      {  
        accessorKey:"providerlocation", 
        header: "Provider Location",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['providerlocation'],
          required: true,
        }
      }, 
      {
        accessorKey:"nutritionactivity", 
        header: "Nutrition Activity",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritionactivity'],
          required: false,
        }
      }, 
      {
        accessorKey:"nutritioncptcode", 
        header: "Nutrition CPT",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritioncpt'],
          required: false,
        }
      }, 
      {
        accessorKey:"nutritionicd10code", 
        header: "Nutrition ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritionicd10'],
          required: false,
        }
      },
      {
        accessorKey:"physicalactivity", 
        header: "Physical Activity",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritionactivity'],
          required: false,
        }
      }, 
      {
        accessorKey:"physicalicd10code", 
        header: "Physical ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['physicalactivityicd10'],
          required: false,
        }
      }, 
      {
        accessorKey:"bmipercentile", 
        header: "BMI Percentile",
        cell: TableCell,
        meta: {
          type: "text",
          required: false,
        }
      }, 
      {
        accessorKey:"weight", 
        header: "Weight",
        cell: TableCell,
        meta: {
          type: "text",
          required: false,
        }
      }, 
      {
        accessorKey:"height", 
        header: "Height",
        cell: TableCell,
        meta: {
          type: "text",
          required: false,
        }
      }, 
      {
        accessorKey:"bmiicd", 
        header: "BMI ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['bmiicd10'],
          required: false,
        }
      }, 
      {
        accessorKey:"specialtycode", 
        header: "Specialty Code",
        cell: TableCell,
        meta: {
          type: "select",
          options: specialtycodes,
          required: true,
        }
      },
      {
        accessorKey:"qsiprovider", 
        header: "QSI Provider",
        cell: TableCell,
        meta: {
          type: "select",
          options: qsiprovidercodes,
          required: true,
        }
      },  
      {
        id: "edit",
        db_table: "wcv",
        cell: EditCell
      }
]

export const columns_wcc = [
      {
        accessorKey:"memberid", 
        header: "Member ID",
        cell: TableCell,
        meta: {
          type: "number",
          required: true,
          pattern: '/[^0-9]/g',
          validate: (value) => {
            return value.length == 8; //date <= today;
          },
          validationMessage: 'Member ID must be 9 digits',
        }
      },
      {
        accessorKey:"servicedate", 
        header: "Service Date",
        cell: TableCell,
        meta: {
          type: "date",
          required: true,
          validate: (value) => {
            const mydate = new Date(value);
            const today = new Date();

            var fiveyearsback = new Date(); 
            fiveyearsback.setHours(0,0,0,0);
            fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

            return (mydate <= today && mydate >= fiveyearsback);
          },
          validationMessage: 'Date cannot be in the future or older than 5 years',
        }
      },      
      {
        accessorKey:"fname", 
        header: "First Name",
        cell: TableCell,
        meta: {
          type: "text",
          required: true,
        }
      },
      {
        accessorKey:"lname", 
        header: "Last Name",
        cell: TableCell,
        meta: {
          type: "text",
          required: true,
        }
      }, 
      {
        accessorKey:"icd10code", 
        header: "ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['icd10'],
          required: true,
        }
      },
      {
        accessorKey:"cptcode", 
        header: "CPT",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['cpt'],
          required: true,
        }
      },  
      {  
        accessorKey:"providerlocation", 
        header: "Provider Location",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['providerlocation'],
          required: true,
        }
      }, 
      {
        accessorKey:"nutritionactivity", 
        header: "Nutrition Activity",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritionactivity'],
          required: false,
        }
      }, 
      {
        accessorKey:"nutritioncptcode", 
        header: "Nutrition CPT",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritioncpt'],
          required: false,
        }
      }, 
      {
        accessorKey:"nutritionicd10code", 
        header: "Nutrition ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritionicd10'],
          required: false,
        }
      },
      {
        accessorKey:"physicalactivity", 
        header: "Physical Activity",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['nutritionactivity'],
          required: false,
        }
      }, 
      {
        accessorKey:"physicalicd10code", 
        header: "Physical ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['physicalactivityicd10'],
          required: false,
        }
      }, 
      {
        accessorKey:"bmipercentile", 
        header: "BMI Percentile",
        cell: TableCell,
        meta: {
          type: "text",
          required: false,
        }
      }, 
      {
        accessorKey:"weight", 
        header: "Weight",
        cell: TableCell,
        meta: {
          type: "text",
          required: false,
        }
      }, 
      {
        accessorKey:"height", 
        header: "Height",
        cell: TableCell,
        meta: {
          type: "text",
          required: false,
        }
      }, 
      {
        accessorKey:"bmiicd", 
        header: "BMI ICD10",
        cell: TableCell,
        meta: {
          type: "select",
          options: formselects['wcv']['bmiicd10'],
          required: false,
        }
      }, 
      {
        accessorKey:"specialtycode", 
        header: "Specialty Code",
        cell: TableCell,
        meta: {
          type: "select",
          options: specialtycodes,
          required: true,
        }
      },
      {
        accessorKey:"qsiprovider", 
        header: "QSI Provider",
        cell: TableCell,
        meta: {
          type: "select",
          options: qsiprovidercodes,
          required: true,
        }
      },  
      {
        id: "edit",
        db_table: "wcc",
        cell: EditCell
      }
]


//Medicaid Number	Patient Family Name	Patient Given Name	Patient Middle Name/Initial	Patient Date of Birth	Converted_DOB	Insurer	Administering Location PIN	Administering Practice	Vaccine Name	Date of Administration	Converted_DOA	CVXCode	CVXdescription


export const columns_dhcf_immunizations = [
  {
  accessorKey:"memberid", 
    header: "Member ID",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"fname", 
    header: "First Name",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"lname", 
    header: "Last Name",
    cell: TableCell,
    meta: {
      type: "text"
    }
  }, 
  {
    accessorKey:"mname", 
      header: "Middle Name",
      cell: TableCell,
      meta: {
        type: "text"
      }
  }, 
  {
  accessorKey:"dob", 
    header: "Date of Birth",
    cell: TableCell,
    meta: {
      type: "date",
      required: true,
      validate: (value) => {
        const mydate = new Date(value);
        const today = new Date();
        var wayback = new Date('1900-01-01 00:00:00'); 

        return (mydate <= today && mydate >= wayback);
      },
      validationMessage: 'Date cannot be in the future or before 1900',
    }
  },
  {
    accessorKey:"insurer", 
      header: "Insurer",
      cell: TableCell,
      meta: {
        type: "text"
      }
  }, 
  {
    accessorKey:"adminlocation", 
      header: "Administering Location",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },   
  {
    accessorKey:"pin", 
      header: "PIN",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },   
  {
    accessorKey:"practice", 
      header: "Administering Practice",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },  
  {
    accessorKey:"servicedate", 
      header: "Date of Aministration",
      cell: TableCell,
      meta: {
        type: "date",
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();

          var fiveyearsback = new Date(); 
          fiveyearsback.setHours(0,0,0,0);
          fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

          return (mydate <= today && mydate >= fiveyearsback);
        },
        validationMessage: 'Date cannot be in the future or older than 5 years',
      }
  },   
  {
    accessorKey:"vaccine", 
      header: "Vaccine",
      cell: TableCell,
      meta: {
        type: "select",
        options: vaccines,
        alsoupdate: "cvxcode"
      }
  },  
  {
  accessorKey:"cvxcode", 
    header: "CVX",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"cvxdescription", 
    header: "CVX Description",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },  
  {
    id: "edit",
    db_table: "dhcf_immunizations",
    cell: EditCell
  }
]

//Medicaid_ID	BeneficiaryDateofBirth	BeneficiaryGenderDescription	Provider_Name	Provider_NPI	Date_of_Service	Payer	Unique_Claim_ID	Claim_Line_Number	Procedure_Code	Tooth_Number	Diagnosis_Codes	Primary_Diagnosis	Visit_Type

export const columns_mmisbump = [
  {
    accessorKey:"memberid", 
    header: "Member ID",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
    accessorKey:"dob", 
    header: "Date of Birth",
    cell: TableCell,
    meta: {
        type: "date",
        required: true,
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();
          var wayback = new Date('1900-01-01 00:00:00'); 

          return (mydate <= today && mydate >= wayback);
        },
        validationMessage: 'Date cannot be in the future or before 1900',
      }
  },
  {
    accessorKey:"gender", 
    header: "Gender",
    cell: TableCell,
    meta: {
      type: "select",
      options: [
        { value: "", label: "Please Select ..."},
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" }
      ]
    }
  },
  {
  accessorKey:"providername", 
    header: "Provider",
    cell: TableCell,
    meta: {
      type: "text"
    }
  }, 
  {
    accessorKey:"npi", 
      header: "Provider NPI",
      cell: TableCell,
      meta: {
        type: "text"
      }
  }, 
  {
  accessorKey:"servicedate", 
    header: "Date of Service",
    cell: TableCell,
    meta: {
      type: "date",
      validate: (value) => {
        const mydate = new Date(value);
        const today = new Date();

        var fiveyearsback = new Date(); 
        fiveyearsback.setHours(0,0,0,0);
        fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

        return (mydate <= today && mydate >= fiveyearsback);
      },
      validationMessage: 'Date cannot be in the future or older than 5 years',
    }
  },
  {
    accessorKey:"payer", 
      header: "Payer",
      cell: TableCell,
      meta: {
        type: "text"
      }
  }, 
  {
    accessorKey:"claimid", 
      header: "Unique Claim ID",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },   
  {
    accessorKey:"claimline", 
      header: "Claim Line Number",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },   
  {
    accessorKey:"procedurecode", 
      header: "Procedure Code",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },   
  {
    accessorKey:"toothnumber", 
      header: "Tooth Number",
      cell: TableCell,
      meta: {
        type: "text"
      }
  }, 
  {
    accessorKey:"dianosiscodes", 
      header: "Diagnosis Codes",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },     
  {
  accessorKey:"primarydiagnosis", 
    header: "Primary diagnosis",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"visittype", 
    header: "Visit Type",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },  
  {
    id: "edit",
    db_table: "mmis_bump",
    cell: EditCell
  }
]

//Member Number	MemberFirstName	MemberMiddleName	MemberLastName	DateOfBirth	VACCINE	CVX	DOS

export const columns_immunization_supplemental = [
  {
  accessorKey:"memberid", 
    header: "Member ID",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"fname", 
    header: "First Name",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"lname", 
    header: "Last Name",
    cell: TableCell,
    meta: {
      type: "text"
    }
  }, 
  {
    accessorKey:"mname", 
      header: "Middle Name",
      cell: TableCell,
      meta: {
        type: "text"
      }
  }, 
  {
  accessorKey:"dob", 
    header: "Date of Birth",
    cell: TableCell,
    meta: {
      type: "date",
      required: true,
      validate: (value) => {
        const mydate = new Date(value);
        const today = new Date();
        var wayback = new Date('1900-01-01 00:00:00'); 

        return (mydate <= today && mydate >= wayback);
      },
      validationMessage: 'Date cannot be in the future or before 1900',
    }
  },
  {
    accessorKey:"vaccine", 
      header: "Vaccine",
      cell: TableCell,
      meta: {
        type: "select",
        options: vaccines,
        alsoupdate: "cvx"
      }
  }, 
  {
    accessorKey:"cvx", 
      header: "CVX",
      cell: TableCell,
      meta: {
        type: "text"
      }
  },   
  {
    accessorKey:"servicedate", 
      header: "Service Date",
      cell: TableCell,
      meta: {
        type: "date",
        validate: (value) => {
          const mydate = new Date(value);
          const today = new Date();

          var fiveyearsback = new Date(); 
          fiveyearsback.setHours(0,0,0,0);
          fiveyearsback.setFullYear(fiveyearsback.getFullYear() - 5);

          return (mydate <= today && mydate >= fiveyearsback);
        },
        validationMessage: 'Date cannot be in the future or older than 5 years',
      }
  },  
  {
    id: "edit",
    db_table: "immunizationsupplemental",
    cell: EditCell
  }
]


//Member Number	Member First Name	Member last Name	ServiceDate	Glucose Collection Date	CPT Code	LDL Collection Date	CPT II Code	Specialty Code Description	QSI Provider Type


export const columns_manual_supplemental = [
  {
  accessorKey:"memberid", 
    header: "Member ID",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"fname", 
    header: "First Name",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"lname", 
    header: "Last Name",
    cell: TableCell,
    meta: {
      type: "text"
    }
  }, 
  {
  accessorKey:"glucosecollectiondate", 
    header: "Glucose Collection Date",
    cell: TableCell,
    meta: {
      type: "date"
    }
  },
  {
  accessorKey:"cptcode", 
    header: "CPT",
    cell: TableCell,
    meta: {
      type: "text"
    }
  },
  {
  accessorKey:"ldlcollectiondate", 
    header: "LDL Collection Date",
    cell: TableCell,
    meta: {
      type: "date"
    }
  }, 
  {
  accessorKey:"cptiicode", 
    header: "CPTII",
    cell: TableCell,
    meta: {
      type: "text"
    }
  }, 
  {
  accessorKey:"specialtycode", 
    header: "Specialty Code",
    cell: TableCell,
    meta: {
      type: "select",
      options: specialtycodes,
    }
  },
  {
  accessorKey:"qsiprovider", 
    header: "QSI Provider",
    cell: TableCell,
    meta: {
      type: "select",
      options: qsiprovidercodes,
    }
  },  
  {
    id: "edit",
    db_table: "manualsupplemental",
    cell: EditCell
  }
]
